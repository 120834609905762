import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { GeistProvider, CssBaseline } from '@geist-ui/react'
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const theme = {
  colors: {
    primary: "#0070f3",
  },
};

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
      <ThemeProvider theme={theme}>
      <GeistProvider>
        <CssBaseline />
        <App />
      </GeistProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

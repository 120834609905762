import React from 'react'
// Geist
import { Page, Text } from '@geist-ui/react'

export default function App() {
  React.useEffect(() => {
    window.location.href = 'https://snapchat.com/add/austisme'
  }, [])
  return (
    <Page dotBackdrop>
      <Page.Header>
        <Text style={{textAlign: 'center'}} h3>hey! redirecting u now</Text>
      </Page.Header>
    </Page>
  )
}